<template>
    <div class="card w-84 card-f-h pr-0">
        <el-tabs type="card">
            <el-tab-pane :label="$t('communication.employees')">
                <div class="contacts-container overflow-y-auto">
                    <Room v-for="room in employeesRoomsComputed" :key="room.id" @click.native="roomSelected(room)">
                        <template #title>
                            {{ room.title }}
                        </template>
                        <template v-if="room.unread_messages" #unread-messages>
                            <p class="text-xs text-white bg-red-500 py-1 px-2 flex justify-center items-center rounded-full">
                                {{ room.unread_messages }}
                            </p>
                        </template>
                    </Room>
                </div>
            </el-tab-pane>
            <el-tab-pane :label="$t('communication.clients')" disabled>
                <div class="contacts-container">
                    <!-- <Room v-for="client in clients" :key="client.id" :user="client">
                        {{ client.name }} {{ client.surname }}
                    </Room> -->
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
export default {
    components: {
        Room: () => import(/* webpackChunkName: "RoomsList/Room" */ './Room'),
    },

    data() {
        return {
            employeesRooms: [],
            // clients:        [],
        };
    },

    computed: {
        employeesRoomsComputed() {
            return this.employeesRooms.map(room => {
                const item = room;
                item.unread_messages = this.$store.state.roomsUnreadMessages.filter(it => it.room_id === item.id)[0]?.count;
                return item;
            });
        },
    },

    created() {
        this.getEmployeesRooms();
        // this.getClients();
    },

    methods: {
        async getEmployeesRooms() {
            this.$wait.start('loading.employees');
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/communication/messages/employees_rooms`);
            this.employeesRooms = data;
            this.$wait.end('loading.employees');
        },

        async getClients() {
            // this.$wait.start('loading.clients');
            // const clients = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/clients`);
            // this.clients = clients.data;
            // this.$wait.end('loading.clients');
        },

        roomSelected(room) {
            this.$emit('roomSelected', room);
        },
    },
};
</script>
<style scoped>
.contacts-container {
    max-height: calc(100vh - 177px);
}
</style>
